import React from 'react';
import OrderForm from '../components/OrderForm';
import logo from '../assets/logo_europ_acro.png';

const EuropAcroForm = () => {
  return (
    <div>
      <OrderForm logo={logo} title="EUROP ACRO" />
    </div>
  );
};

export default EuropAcroForm;
