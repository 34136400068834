import React from 'react';
import { Box, Typography, Grid, IconButton, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import documentTypes from '../documentTypes.json';
import './Attachments.css';

const Attachments = ({ attachments, setAttachments, fileErrors, setFileErrors }) => {
  const handleAttachmentChange = (index, e) => {
    const { name, value, files } = e.target;
    const newAttachments = [...attachments];
    if (name === 'file') {
      const file = files[0];
      const fileType = file.type;
      if (fileType === 'application/pdf' || fileType === 'image/jpeg') {
        newAttachments[index].file = file;
        setFileErrors(fileErrors.filter((_, i) => i !== index));
      } else {
        setFileErrors([...fileErrors, index]);
        return;
      }
    } else {
      newAttachments[index].type = value;
    }
    setAttachments(newAttachments);
  };

  const handleDrop = (index, e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const newAttachments = [...attachments];
    const file = files[0];
    const fileType = file.type;
    if (fileType === 'application/pdf' || fileType === 'image/jpeg') {
      newAttachments[index].file = file;
      setFileErrors(fileErrors.filter((_, i) => i !== index));
    } else {
      setFileErrors([...fileErrors, index]);
      return;
    }
    setAttachments(newAttachments);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleBrowseClick = (index) => {
    document.getElementById(`fileInput${index}`).click();
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
    setFileErrors(fileErrors.filter((_, i) => i !== index));
  };

  const handleAddAttachment = () => {
    setAttachments([...attachments, { file: null, type: '' }]);
  };

  return (
    <>
      {attachments.map((attachment, index) => (
        <Box
          mb={2}
          key={index}
          onDrop={(e) => handleDrop(index, e)}
          onDragOver={handleDragOver}
          style={{ backgroundColor: '#f9f9f9', border: '1px dashed #ccc', borderRadius: '5px', padding: '10px' }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2} md={2}>
              <Box
                onClick={() => handleBrowseClick(index)}
                style={{
                  textAlign: 'center',
                  padding: '10px',
                  border: '1px dashed #ccc',
                  borderRadius: '5px',
                  backgroundColor: '#f0f0f0',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <Typography variant="body2">Parcourir</Typography>
              </Box>
              <input
                type="file"
                name="file"
                id={`fileInput${index}`}
                hidden
                onChange={(e) => handleAttachmentChange(index, e)}
                accept=".pdf, .jpg, .jpeg"
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              {attachment.file ? (
                <Typography variant="body2">{attachment.file.name}</Typography>
              ) : (
                <Typography variant="body2">Glissez et déposez un fichier ici ou cliquez pour parcourir</Typography>
              )}
              {fileErrors.includes(index) && (
                <Typography color="error" variant="body2">
                  Type de fichier non valide. Veuillez sélectionner un fichier PDF ou une image JPG.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ textAlign: 'right' }}>
              <FormControl fullWidth required={Boolean(attachment.file)}>
                <InputLabel>Type de Document</InputLabel>
                <Select
                  name="type"
                  value={attachment.type}
                  onChange={(e) => handleAttachmentChange(index, e)}
                >
                  {documentTypes.types.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1} md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => handleRemoveAttachment(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Box mt={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="button" variant="contained" color="primary" onClick={handleAddAttachment}>
          Ajouter un Document
        </Button>
      </Box>
    </>
  );
};

export default Attachments;
