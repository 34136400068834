import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import './Signature.css';

const Signature = ({ sigCanvas, signatureError, clearSignature, setSignatureError }) => {
  const handleEnd = () => {
    setSignatureError(false);
  };

  return (
    <Box mt={2} style={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        style={{
          backgroundColor: '#f0f0f0',
          border: '1px solid black',
          padding: '20px',
          borderRadius: '5px',
          width: '100%',
          maxWidth: '380px'
        }}
      >
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: 380, height: 200, className: 'sigCanvas' }}
          ref={sigCanvas}
          onEnd={handleEnd}
        />
        {signatureError && (
          <Typography color="error" align="center" variant="body2">
            La signature est obligatoire
          </Typography>
        )}
        <Box mt={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="secondary" onClick={clearSignature}>
            Effacer
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Signature;
