import axios from "axios";

export const sendFileToZeendoc = async (file, fileName, collId) => {
  const formData = new FormData();

  // Ajoutez le fichier et ses informations dans le FormData
  formData.append("file", file, fileName);
  formData.append("userLogin", process.env.REACT_APP_LOGIN);
  formData.append("userPassword", process.env.REACT_APP_PASSWORD);
  formData.append("urlZeendoc", process.env.REACT_APP_API_URL);
  formData.append("collId", collId); // Ajout de l'ID de collection

  try {
    const response = await axios.post(`${process.env.REACT_APP_CHEMIN_PHP}/uploadpdf.php`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Fichier téléchargé avec succès:", response.data);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erreur lors du téléchargement du fichier :", error);
    return { success: false, error };
  }
};
