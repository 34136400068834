import React from "react";
import { Grid, TextField, IconButton, InputAdornment, Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const OrderLines = ({ lines, handleLineChange, handleAddLine, handleRemoveLine }) => {
  return (
    <>
      {lines.map((line, index) => (
        <Box
          mb={2}
          key={index}
          style={{
            backgroundColor: index % 2 === 0 ? "#BBCAE7" : "#ffffff",
            padding: "10px",
            borderRadius: "5px",
          }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
              <TextField label="Désignation" name="designation" value={line.designation} onChange={(e) => handleLineChange(index, e)} fullWidth />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField label="Quantité" name="quantity" type="number" value={line.quantity} onChange={(e) => handleLineChange(index, e)} fullWidth required={line.designation !== ""} InputProps={{ style: { textAlign: "center" } }} />
            </Grid>

            <Grid item xs={12} sm={1.9}>
              <TextField
                label="Prix Unitaire"
                name="unitPrice"
                type="number"
                value={line.unitPrice}
                onChange={(e) => handleLineChange(index, e)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  style: { textAlign: "right" },
                }}
                fullWidth
                required={line.designation !== ""}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Prix Total"
                name="totalPrice"
                type="number"
                value={((line.quantity || 0) * (line.unitPrice || 0) * (1 - (line.discount || 0) / 100)).toFixed(2)}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  style: { textAlign: "right" },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1} style={{ display: "flex", justifyContent: "center" }}>
              <IconButton onClick={() => handleRemoveLine(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Box mt={2} style={{ display: "flex", justifyContent: "center" }}>
        <Button type="button" variant="contained" color="primary" onClick={handleAddLine}>
          Ajouter une Ligne
        </Button>
      </Box>
    </>
  );
};

export default OrderLines;
