import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Draggable from 'react-draggable';
import menuIcon from '../assets/menu.png';
import './Navigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Navigation = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const draggableRef = useRef(null);

  // Récupérer l'adresse e-mail depuis l'URL
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('mail') || '';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const updateMenuPosition = () => {
      if (isOpen) {
        const rect = draggableRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const menuHeight = 200; // Estimated height of the menu

        let top = rect.bottom + 10;
        if (top + menuHeight > windowHeight) {
          top = rect.top - menuHeight - 10;
        }

        setMenuPosition({ top: top, left: rect.left });
      }
    };

    updateMenuPosition();
    window.addEventListener('scroll', updateMenuPosition);
    return () => {
      window.removeEventListener('scroll', updateMenuPosition);
    };
  }, [isOpen]);

  const isMobile = window.innerWidth < 600;
  const menuIconPosition = isMobile ? 'menu-icon-right' : 'menu-icon-left';

  useEffect(() => {
    if (isMobile) {
      setMenuPosition({ top: 80, right: 20 }); // Static position for mobile
    }
  }, [isMobile]);

  if (location.pathname === '/') {
    return null; // Do not render the navigation menu on the home page
  }

  // Générer les liens avec le paramètre mail
  const generateLink = (path) => `${path}${email ? `?mail=${encodeURIComponent(email)}` : ''}`;

  return (
    <>
      <Draggable nodeRef={draggableRef} bounds="parent" disabled={isMobile}>
        <div
          ref={draggableRef}
          className={`menu-container ${menuIconPosition}`}
          onClick={toggleMenu}
        >
          {isMobile && <div className="menu-text">MENU</div>}
          <img
            src={menuIcon}
            alt="Menu"
            className="menu-icon"
            style={{ width: '95px', height: '95px' }} // Increased size
          />
        </div>
      </Draggable>
      {isOpen && (
        <div
          className={`menu ${isMobile ? 'menu-right' : 'menu-left'}`}
          style={{ top: menuPosition.top, left: menuPosition.left }}
        >
          <Link to={generateLink('/europ-tp')} onClick={toggleMenu} className="menu-item">EUROP TP</Link>
          <Link to={generateLink('/europ-acro')} onClick={toggleMenu} className="menu-item">EUROP ACRO</Link>
          <Link to={generateLink('/lc-metallerie')} onClick={toggleMenu} className="menu-item">LC METALLERIE</Link>
          <Link to={generateLink('/ete')} onClick={toggleMenu} className="menu-item">ETE</Link>
          <Link to={generateLink('/lombart')} onClick={toggleMenu} className="menu-item">LOMBART</Link>
          <Link to={generateLink('/')} onClick={toggleMenu} className="menu-item">
            <FontAwesomeIcon icon={faHome} style={{ marginRight: '8px' }} />
            ACCUEIL
          </Link>
        </div>
      )}
    </>
  );
};

export default Navigation;
