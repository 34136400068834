import React from 'react';
import OrderForm from '../components/OrderForm';
import logo from '../assets/logo_europtp.png';

const EuropTPForm = () => {
  return (
    <div>
      <OrderForm logo={logo} title="EUROP TP" />
    </div>
  );
};

export default EuropTPForm;
