import React from "react";
import OrderForm from "../components/OrderForm";
import logo from "../assets/logo_ETE.png";

const LombartForm = () => {
  return (
    <div>
      <OrderForm logo={logo} title="LOMBART" />
    </div>
  );
};

export default LombartForm;
